import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { APIStatus } from './APIStatus'

const initialState = {
    processedData: null,
    processedDetailData: null,
    todaysProcessedData : null,
    todaysProcessedDetailData : null,
    processedListLoading : true,
    isLoading : 'loading'
}

export const getProcessedData = createAsyncThunk('getProcessedData', async (payload) => {
    const response = await axios.get(`/${payload.organization}/get-processed-data?file_created_at=${payload.dateSelected}&file_type=${payload.filesToFilter.join(',')}`);
    return response;
})

export const getProcessedDetailData = createAsyncThunk('getProcessedDetailData', async (payload) => {
    const response = await axios.get(`/${payload.organization}/get-processed-data/${payload.dataId}`);
    return response;
})

export const getTodaysProcessedData = createAsyncThunk('getTodaysProcessedData', async (payload) => {
    const response = await axios.get(`/${payload.organization}/get-processed-data?file_type=blockage_output&file_created_at=${payload.date}`).then((resp)=>{
        let childData = []
        if(resp.data.results && resp.data.results.length > 0){
            childData = axios.get(`/${payload.organization}/get-processed-data/${resp.data.results[0].id}`)
        }
        return childData
    });
    return response;
})


const processedDataSlice = createSlice({
    name: 'processedData',
    initialState,
    reducers: {
        reset(state, action){
            state.processedData = null;
            state.processedDetailData = null;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getProcessedData.pending, (state, action) => {
                state.processedListLoading = true;
            })
            .addCase(getProcessedData.fulfilled, (state, action) => {
                state.isLoading = APIStatus.loaded;
                state.processedData = action.payload ? action.payload.data.results :  null;
                state.processedListLoading = false
            })
            .addCase(getProcessedDetailData.pending, (state, action) => {
                state.isLoading = APIStatus.loading;
            })
            .addCase(getProcessedDetailData.fulfilled, (state, action) => {
                state.isLoading = APIStatus.loaded;
                state.processedDetailData = action.payload ? action.payload.data :  null;
            })
            .addCase(getTodaysProcessedData.pending, (state, action) => {
                state.isLoading = APIStatus.loading;
            })
            .addCase(getTodaysProcessedData.fulfilled, (state, action) => {
                state.isLoading = APIStatus.loaded;
                state.todaysProcessedData = action.payload ? action.payload.data :  null;
            })
    }
})

export const { load, reset } = processedDataSlice.actions

export default processedDataSlice.reducer