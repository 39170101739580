import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { sumBy, map } from "lodash";

import { APIStatus } from "./APIStatus";

const initialState = {
  durationMonitoringDashboardFilters: null,
  durationMonitoringDetailsStatus: APIStatus.idle,
  durationMonitoringDetails: null,
  durationMonitoringDetailsError: null,
};

export const getEDMAlarms = createAsyncThunk(
  "getEDMAlarms",
  async (payload) => {
    const response = await axios.get(payload.url);
    return response;
  }
);

const edmDashboardSlice = createSlice({
  name: "edmDashboardSlice",
  initialState,
  reducers: {
    setDurationMonitoringDashboardFilterStore(state, action) {
      state.durationMonitoringDashboardFilters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEDMAlarms.pending, (state, action) => {
        state.durationMonitoringDetailsStatus = APIStatus.loading;
      })
      .addCase(getEDMAlarms.fulfilled, (state, action) => {
        if (!action?.payload?.data) {
          state.durationMonitoringDetails = null;
          state.durationMonitoringDetailsStatus = APIStatus.failed;
          state.durationMonitoringDetailsError = "Something went wrong!";
          return;
        }

        const payload = action.payload.data;

        const overview = map(payload?.overview?.duration, (value, key) => ({
          date: key,
          total_duration: value,
        }));

        const summary = map(payload.summary, (value, key) => ({
          deviceId: key,
          total_duration: value.total_duration,
          alarms: value.alarms || [],
        }));

        state.durationMonitoringDetailsStatus = APIStatus.loaded;
        state.durationMonitoringDetails = {
          totalEvents: summary.length,
          totalDuration: sumBy(summary, "total_duration"),
          overview,
          summary,
        };
      })
      .addCase(getEDMAlarms.rejected, (state, action) => {
        state.durationMonitoringDetailsStatus = APIStatus.failed;
        state.durationMonitoringDetailsError = action.error;
      });
  },
});

export const { setDurationMonitoringDashboardFilterStore } =
  edmDashboardSlice.actions;

export default edmDashboardSlice.reducer;
