import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";

import { APIStatus } from "./APIStatus";

const initialState = {
  meteringDashboardStatus: APIStatus.idle,
  meterList: null,
  meterListError: null,
  meterInfoTableList: null,
  meterInfoTableListError: null,
  meterDetailsList: null,
  meterDetailsListError: null,
};

export const getEnergyFeed = createAsyncThunk(
  "getEnergyFeed",
  async (payload) => {
    const response = await axios.get(
      `/${payload.organization}/feeds/Energy/data-cumulative/${payload.startDate}/${payload.endDate}`
    );
    return response;
  }
);

export const getMeterInfoTable = createAsyncThunk(
  "getMeterInfoTable",
  async (payload) => {
    const response = await axios.get(
      `/${payload.organization}/feeds/Energy/data-cumulative/${payload.startDate}/${payload.endDate}`
    );
    return response;
  }
);

const frequencyMapping = {
  10: 10, // 10 minutes
  30: 30, // 30 minutes
  60: 1, // 1 hour
  240: 4, // 4 hours
  480: 8, // 8 hours
  720: 12, // 12 hours
  1440: 24, // 24 hours
};

const frequencyUnitMapping = {
  10: "minutes",
  30: "minutes",
  60: "hour",
  240: "hours",
  480: "hours",
  720: "hours",
  1440: "hours",
};

export const getFeedsCumulative = createAsyncThunk(
  "getFeedsCumulative",
  async (payload) => {
    let apiEndPoint;
    let isDayExtended = false;

    if (payload.wise === "month" || payload.wise === "week") {
      apiEndPoint = `/${payload.organization}/feeds-cumulative/${payload.feedKey}/${payload.startDate}/${payload.endDate}/${payload.wise}`;
    } else {
      const frequency = payload.frequency;
      const startFromDate = moment(payload.startDate)
        .startOf("day")
        .subtract(frequencyMapping[frequency], frequencyUnitMapping[frequency])
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
      const endToDate = moment(payload.endDate)
        .endOf("day")
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");

      apiEndPoint = `/${payload.organization}/feeds/${payload.feedKey}/data-cumulative/${frequency}?completed_at__range=${startFromDate},${endToDate}`;
      isDayExtended = true;
    }
    const response = await axios.get(apiEndPoint);
    let data = response.data;

    if (isDayExtended) {
      const startFromDate = moment(payload.startDate).startOf("day");
      const endToDate = moment(payload.endDate).endOf("day");

      data = data.filter((item) => {
        const itemDate = moment
          .utc(item[0])
          .local()
          .add(payload.frequency, "minutes")
          .subtract(1, "second");

        return itemDate.isBetween(startFromDate, endToDate, "day", "[]");
      });
      isDayExtended = false;
    }

    return data;
  }
);

const meteringDashboardSlice = createSlice({
  name: "meteringDashboardSlice",
  initialState,
  reducers: {
    reset(state, action) {
      state = { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEnergyFeed.pending, (state, action) => {
        state.meteringDashboardStatus = APIStatus.loading;
      })
      .addCase(getEnergyFeed.fulfilled, (state, action) => {
        state.meteringDashboardStatus = APIStatus.loaded;
        state.meterList = action.payload ? action.payload.data : null;
      })
      .addCase(getEnergyFeed.rejected, (state, action) => {
        state.meteringDashboardStatus = APIStatus.failed;
        state.meterListError = action.error;
      })
      .addCase(getMeterInfoTable.pending, (state, action) => {
        state.meteringDashboardStatus = APIStatus.loading;
      })
      .addCase(getMeterInfoTable.fulfilled, (state, action) => {
        state.meteringDashboardStatus = APIStatus.loaded;
        state.meterInfoTableList = action.payload ? action.payload.data : null;
      })
      .addCase(getMeterInfoTable.rejected, (state, action) => {
        state.meteringDashboardStatus = APIStatus.failed;
        state.meterInfoTableListError = action.error;
      })
      .addCase(getFeedsCumulative.pending, (state, action) => {
        state.meteringDashboardStatus = APIStatus.loading;
      })
      .addCase(getFeedsCumulative.fulfilled, (state, action) => {
        state.meteringDashboardStatus = APIStatus.loaded;
        state.meterDetailsList = action.payload || null;
      })
      .addCase(getFeedsCumulative.rejected, (state, action) => {
        state.meteringDashboardStatus = APIStatus.failed;
        state.meterDetailsListError = action.error;
      });
  },
});

export const { reset } = meteringDashboardSlice.actions;

export default meteringDashboardSlice.reducer;
