import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { APIStatus } from './APIStatus'

const initialState = {
    orgBillingInfoStatus: APIStatus.idle,
    orgBillingInfo: null,
    orgBillingInfoError: null,
    deviceBillingInfoStatus: APIStatus.idle,
    deviceBillingInfo: null,
    deviceBillingInfoError: null,
    smsLogsStatus: APIStatus.idle,
    smsLogs: null,
    smsLogsError: null,
    emailLogsStatus: APIStatus.idle,
    emailLogs: null,
    emailLogsError: null,
}

export const getOrgBillingInfo = createAsyncThunk('getOrgBillingInfo', async (payload) => {
    const response = await axios.get(`/${payload.organization}/billing`);
    return response;
})

export const getDeviceBillingInfo = createAsyncThunk('getDeviceBillingInfo', async (payload) => {
    const url = `/${payload.organization}/billing/subscriptions`;
    const response = await axios.get(url);
    const { results, count } = response.data;

    if (count <= 100) {
        return { data: { results } };
    }
    const requests = [];
    for (let j = 1; j < count / 100; j++) {
        requests.push(axios.get(`${url}&offset=${j * 100}`));
    }

    const dataArr = await Promise.all(requests).then((responses) =>
        responses.reduce((acc, res) => acc.concat(res.data.results), results)
    );
    return { data: { results: dataArr } };
})

export const getSmsLogs = createAsyncThunk('getSmsLogs', async (payload) => {
    const url = `/${payload.organization}/billing/smslogs?date_sent__gt=${payload.startDate}&date_sent__lt=${payload.endDate}`;
    const response = await axios.get(url);
    const { results, count } = response.data;

    if (count <= 100) {
        return { data: { results } };
    }
    const requests = [];
    for (let j = 1; j < count / 100; j++) {
        requests.push(axios.get(`${url}&offset=${j * 100}`));
    }

    const dataArr = await Promise.all(requests).then((responses) =>
        responses.reduce((acc, res) => acc.concat(res.data.results), results)
    );
    return { data: { results: dataArr } };
})

export const getEmailLogs = createAsyncThunk('getEmailLogs', async (payload) => {
    const url = `/${payload.organization}/billing/emaillogs?created_at__gt=${payload.startDate}&created_at__lt=${payload.endDate}`;
    const response = await axios.get(url);
    const { results, count } = response.data;

    if (count <= 100) {
        return { data: { results } };
    }
    const requests = [];
    for (let j = 1; j < count / 100; j++) {
        requests.push(axios.get(`${url}&offset=${j * 100}`));
    }

    const dataArr = await Promise.all(requests).then((responses) =>
        responses.reduce((acc, res) => acc.concat(res.data.results), results)
    );
    return { data: { results: dataArr } };
})

const billingInfoSlice = createSlice({
    name: 'billingInfoSlice',
    initialState,
    reducers: {
        reset(state, action) {
            state = { ...initialState };
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getOrgBillingInfo.pending, (state, action) => {
                state.orgBillingInfoStatus = APIStatus.loading;
            })
            .addCase(getOrgBillingInfo.fulfilled, (state, action) => {
                state.orgBillingInfoStatus = APIStatus.loaded;
                state.orgBillingInfo = action.payload ? action.payload.data : null;
            })
            .addCase(getOrgBillingInfo.rejected, (state, action) => {
                state.orgBillingInfoStatus = APIStatus.failed;
                state.orgBillingInfoError = action.error;
            })
            .addCase(getDeviceBillingInfo.pending, (state, action) => {
                state.deviceBillingInfoStatus = APIStatus.loading;
            })
            .addCase(getDeviceBillingInfo.fulfilled, (state, action) => {
                state.deviceBillingInfoStatus = APIStatus.loaded;
                state.deviceBillingInfo = action.payload ? action.payload.data.results : null;
            })
            .addCase(getDeviceBillingInfo.rejected, (state, action) => {
                state.deviceBillingInfoStatus = APIStatus.failed;
                state.deviceBillingInfoError = action.error;
            })
            .addCase(getSmsLogs.pending, (state, action) => {
                state.smsLogsStatus = APIStatus.loading;
            })
            .addCase(getSmsLogs.fulfilled, (state, action) => {
                state.smsLogsStatus = APIStatus.loaded;
                state.smsLogs = action.payload ? action.payload.data.results : null;
            })
            .addCase(getSmsLogs.rejected, (state, action) => {
                state.smsLogsStatus = APIStatus.failed;
                state.smsLogsError = action.error;
            })
            .addCase(getEmailLogs.pending, (state, action) => {
                state.emailLogsStatus = APIStatus.loading;
            })
            .addCase(getEmailLogs.fulfilled, (state, action) => {
                state.emailLogsStatus = APIStatus.loaded;
                state.emailLogs = action.payload ? action.payload.data.results : null;
            })
            .addCase(getEmailLogs.rejected, (state, action) => {
                state.emailLogsStatus = APIStatus.failed;
                state.emailLogsError = action.error;
            })
    }
})

export const { reset } = billingInfoSlice.actions

export default billingInfoSlice.reducer