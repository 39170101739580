import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    fontFamily: "Karla,sans-serif",
    marginBottom: "2%",
    "&:hover, &:focus": {
      backgroundColor: "#e3f7f7",
      borderRadius: "8px",
    },
  },
  linkActive: {
    // backgroundColor: theme.palette.background.light,
    backgroundColor: "#e3f7f7",
    borderRadius: "8px",
    // width: "90%",
  },
  linkNested: {
    paddingLeft: 0,
    "&:hover, &:focus": {
      backgroundColor: "#e3f7f7",
    },
  },
  linkIcon: {
    marginRight: "2px",
    //color: theme.palette.text.secondary + "99",
    //transition: theme.transitions.create("color"),
    minWidth: 10,
    width: 24,
    display: "flex",
    justifyContent: "left",
  },
  linkIconActive: {
    color: "#2d4d76",
  },
  linkText: {
    padding: "0 !important",
    color: "#698D8D !important", //theme.palette.text.secondary + "CC",
    //transition: theme.transitions.create(["opacity", "color"]),
    fontSize: "14px !important",
    fontWeight: "bold !important",
  },
  linkTextActive: {
    color: "#2d4d76 !important",
    backgroundColor: "#e3f7f7 !important",
    fontWeight: "bold !important",
  },
  linkTextHidden: {
    opacity: 0,
  },
  nestedList: {
    paddingLeft: "4px",
  },
  sectionTitle: {
    marginLeft: "6px",
    marginTop: "4px",
    marginBottom: "4px",
  },
  divider: {
    marginTop: "16px !important",
    marginBottom: "16px !important",
    height: "1px !important",
    backgroundColor: "#D8D8D880 !important",
  },
}));
