import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { APIStatus } from "./APIStatus";

const initialState = {
  isLoading: APIStatus.loading,
  scheduleReportIsLoading: APIStatus.loading,
  triggers: null,
  addTriggerStatus: APIStatus.idle,
  addTriggerReceipt: null,
  addTriggerError: null,
  updateTriggerStatus: APIStatus.idle,
  updateTriggerReceipt: null,
  updateTriggerError: null,
  deleteTriggerStatus: APIStatus.idle,
  deleteTriggerReceipt: null,
  deleteTriggerError: null,
  scheduleReportStatus: APIStatus.idle,
  scheduleReportError: null,
  scheduleReports: null,
  addScheduleReportStatus: APIStatus.idle,
  addScheduleReportReceipt: null,
  addScheduleReportError: null,
  updateScheduleReportStatus: APIStatus.idle,
  updateScheduleReportReceipt: null,
  updateScheduleReportError: null,
  deleteScheduleReportStatus: APIStatus.idle,
  deleteScheduleReportReceipt: null,
  deleteScheduleReportError: null,
  scheduleReportDeviceFeeds: null,
  emailTemplateStatus: APIStatus.idle,
  emailTemplate: null,
  emailTemplateError: null,
};

export const getTriggers = createAsyncThunk("getTriggers", async (payload) => {
  const response = await axios.get(payload.url);
  return response;
});

export const getScheduleReport = createAsyncThunk(
  "getScheduleReport",
  async (payload) => {
    const response = await axios.get(payload.url);
    return response;
  }
);

export const addTrigger = createAsyncThunk("addTrigger", async (payload) => {
  const response = await axios.post(payload.url, payload.data);
  return response;
});

export const addScheduleReport = createAsyncThunk(
  "addScheduleReport",
  async (payload) => {
    const response = await axios.post(payload.url, payload.data);
    return response;
  }
);

export const updateTrigger = createAsyncThunk(
  "updateTrigger",
  async (payload) => {
    const response = await axios.patch(payload.url, payload.data);
    return response;
  }
);

export const updateScheduleReport = createAsyncThunk(
  "updateScheduleReport",
  async (payload) => {
    const response = await axios.patch(payload.url, payload.data);
    return response;
  }
);

export const deleteTrigger = createAsyncThunk(
  "deleteTrigger",
  async (payload) => {
    const response = await axios.delete(payload.url);
    return response;
  }
);

export const deleteScheduleReport = createAsyncThunk(
  "deleteScheduleReport",
  async (payload) => {
    const response = await axios.delete(payload.url);
    return response;
  }
);

export const getAllTriggerTemplates = createAsyncThunk(
  "getAllTriggerTemplates",
  async (payload) => {
    const response = await axios.get(
      `/${payload.organization}/email-templates`
    );
    return response;
  }
);

export const getScheduleReportsDeviceFeeds = createAsyncThunk(
  "getScheduleReportsDeviceFeeds",
  async (payload) => {
    if (payload.ids.length > 0) {
      const reqArr = payload.ids.map((f) => {
        return axios.get(`/${payload.organization}/feeds?device__id=${f}`);
      });
      const response = await axios.all(reqArr).then((feeds) => {
        let feedList = [];
        feeds.forEach((feed) => {
          feedList = [...feedList, ...feed.data.results];
        });
        return feedList;
      });
      return response;
    }
    return [];
  }
);

const feedsSlice = createSlice({
  name: "triggers",
  initialState,
  reducers: {
    reset(state, action) {
      state.feeds = null;
      state.deviceFeeds = null;
    },
    resetaddTrigger(state, action) {
      state.addTriggerStatus = APIStatus.idle;
      state.addTriggerReceipt = null;
      state.addTriggerError = null;
    },
    resetupdateTrigger(state, action) {
      state.updateTriggerStatus = APIStatus.idle;
      state.updateTriggerReceipt = null;
      state.updateTriggerError = null;
    },
    resetupdateScheduleReport(state, action) {
      state.updateScheduleReportStatus = APIStatus.idle;
      state.updateScheduleReportReceipt = null;
      state.updateScheduleReportError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTriggers.pending, (state, action) => {
        // state.isLoading = APIStatus.loading;
      })
      .addCase(getTriggers.fulfilled, (state, action) => {
        state.addTriggerReceipt = null;
        state.addTriggerError = null;
        state.updateTriggerReceipt = null;
        state.updateTriggerError = null;
        state.deleteTriggerReceipt = null;
        state.deleteTriggerError = null;
        state.isLoading = APIStatus.loaded;
        state.triggers = action.payload ? action.payload.data.results : null;
      })
      .addCase(addTrigger.pending, (state, action) => {
        state.addTriggerStatus = APIStatus.loading;
      })
      .addCase(addTrigger.fulfilled, (state, action) => {
        state.addTriggerStatus = APIStatus.loaded;
        state.addTriggerReceipt = action.payload;
        state.addTriggerError = null;
      })
      .addCase(addTrigger.rejected, (state, action) => {
        state.addTriggerStatus = APIStatus.failed;
        state.addTriggerError = action.error;
      })
      .addCase(updateTrigger.pending, (state, action) => {
        state.updateTriggerStatus = APIStatus.loading;
      })
      .addCase(updateTrigger.fulfilled, (state, action) => {
        state.updateTriggerStatus = APIStatus.loaded;
        state.updateTriggerReceipt = action.payload;
        state.updateTriggerError = null;
      })
      .addCase(updateTrigger.rejected, (state, action) => {
        state.updateTriggerStatus = APIStatus.failed;
        state.updateTriggerError = action.error;
      })
      .addCase(deleteTrigger.pending, (state, action) => {
        state.deleteTriggerStatus = APIStatus.loading;
      })
      .addCase(deleteTrigger.fulfilled, (state, action) => {
        state.deleteTriggerStatus = APIStatus.loaded;
        state.deleteTriggerReceipt = "Deleted Successfully";
        state.deleteTriggerError = null;
      })
      .addCase(deleteTrigger.rejected, (state, action) => {
        state.deleteTriggerStatus = APIStatus.failed;
        state.deleteTriggerError = action.error;
      })
      .addCase(getScheduleReport.pending, (state, action) => {
        state.scheduleReportStatus = APIStatus.loading;
      })
      .addCase(getScheduleReport.fulfilled, (state, action) => {
        state.addScheduleReportReceipt = null;
        state.addScheduleReportError = null;
        state.updateScheduleReportReceipt = null;
        state.updateScheduleReportError = null;
        state.deleteScheduleReportReceipt = null;
        state.deleteScheduleReportError = null;
        state.scheduleReportIsLoading = APIStatus.loaded;
        state.scheduleReports = action.payload
          ? action.payload.data.results
          : null;
      })
      .addCase(getScheduleReport.rejected, (state, action) => {
        state.scheduleReportStatus = APIStatus.failed;
      })
      .addCase(addScheduleReport.pending, (state, action) => {
        state.addScheduleReportStatus = APIStatus.loading;
      })
      .addCase(addScheduleReport.fulfilled, (state, action) => {
        state.addScheduleReportStatus = APIStatus.loaded;
        state.addScheduleReportReceipt = action.payload;
        state.addScheduleReportError = null;
      })
      .addCase(addScheduleReport.rejected, (state, action) => {
        state.addScheduleReportStatus = APIStatus.failed;
        state.addScheduleReportError = action.error;
      })
      .addCase(getAllTriggerTemplates.pending, (state, action) => {
        state.emailTemplateStatus = APIStatus.loading;
      })
      .addCase(getAllTriggerTemplates.fulfilled, (state, action) => {
        state.emailTemplateStatus = APIStatus.loaded;
        state.emailTemplate = action.payload;
        state.emailTemplateError = null;
      })
      .addCase(getAllTriggerTemplates.rejected, (state, action) => {
        state.emailTemplateStatus = APIStatus.failed;
        state.emailTemplateError = action.error;
      })
      .addCase(updateScheduleReport.pending, (state, action) => {
        state.updateScheduleReportStatus = APIStatus.loading;
      })
      .addCase(updateScheduleReport.fulfilled, (state, action) => {
        state.updateScheduleReportStatus = APIStatus.loaded;
        state.updateScheduleReportReceipt = action.payload;
        state.updateScheduleReportError = null;
      })
      .addCase(updateScheduleReport.rejected, (state, action) => {
        state.updateScheduleReportStatus = APIStatus.failed;
        state.updateScheduleReportError = action.error;
      })
      .addCase(deleteScheduleReport.pending, (state, action) => {
        state.deleteScheduleReportStatus = APIStatus.loading;
      })
      .addCase(deleteScheduleReport.fulfilled, (state, action) => {
        state.deleteScheduleReportStatus = APIStatus.loaded;
        state.deleteScheduleReportReceipt = "Deleted Successfully";
        state.deleteScheduleReportError = null;
      })
      .addCase(deleteScheduleReport.rejected, (state, action) => {
        state.deleteScheduleReportStatus = APIStatus.failed;
        state.deleteScheduleReportError = action.error;
      })
      .addCase(getScheduleReportsDeviceFeeds.pending, (state, action) => {
        //state.isLoading = APIStatus.loading;
      })
      .addCase(getScheduleReportsDeviceFeeds.fulfilled, (state, action) => {
        state.scheduleReportDeviceFeeds = action.payload
          ? action.payload
          : null;
      });
  },
});

export const {
  load,
  resetaddTrigger,
  resetupdateTrigger,
  resetupdateScheduleReport,
} = feedsSlice.actions;

export default feedsSlice.reducer;
