import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { APIStatus } from './APIStatus'

const initialState = {
    userSettingsStatus: APIStatus.idle,
    userSettings: null,
    userSettingsError: null,
    appconfigNBIOT: null,
    usersList: null,
    usersListError: null,
    addUserDetail: null,
    addUserError: null,
    updateUserDetail: null,
    updateUserError: null,
    deleteUserDetail: null,
    deleteUserError: null,
    usersActivies: null,
    usersActiviesError: null,
}

export const getUserSettings = createAsyncThunk('getUserSettings', async (payload) => {
    const response = await axios.get(`/${payload.organization}/users/${payload.email}/settings`);
    return response;
})

export const getUsers = createAsyncThunk("getUsers", async (payload) => {
    let organization = payload.organization;
    const response = await axios.get(`/${organization}/users`);
    return response;
});

export const getUsersActivies = createAsyncThunk("getUsersActivies", async (payload) => {
    let url = `/${payload.organization}/auditlog?limit=999`;
    const response = await axios.get(url);
    return response;
});

export const addUsers = createAsyncThunk("addUsers", async (payload) => {
    let organization = payload.organization;
    const response = await axios.post(`/${organization}/users`, payload.data);
    return response;
});

export const updateUser = createAsyncThunk("updateUser", async (payload) => {
    let organization = payload.organization;
    let username = payload.data.user;
    const response = await axios.patch(`/${organization}/users/${username}`, payload.data);
    return response;
});

export const deleteUser = createAsyncThunk("deleteUser", async (payload) => {
    let organization = payload.organization;
    let username = payload.userName;
    const response = await axios.delete(`/${organization}/users/${username}`);
    return response;
});

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        reset(state, action) {
            state = { ...initialState };
        },
        setAppconfigNBIOT(state, action) {
            state.appconfigNBIOT = action.payload;
        },
        resetAppconfigNBIOT(state, action) {
            state.appconfigNBIOT = null;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getUserSettings.pending, (state, action) => {
                state.userSettingsStatus = APIStatus.loading;
            })
            .addCase(getUserSettings.fulfilled, (state, action) => {
                state.userSettingsStatus = APIStatus.loaded;
                state.userSettings = action.payload ? action.payload.data : null;
            })
            .addCase(getUserSettings.rejected, (state, action) => {
                state.userSettingsStatus = APIStatus.failed;
                state.userSettingsError = action.error;
            })
            .addCase(getUsers.pending, (state, action) => {
                state.userSettingsStatus = APIStatus.loading;
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                state.userSettingsStatus = APIStatus.loaded;
                state.usersList = action.payload.data;
            })
            .addCase(getUsers.rejected, (state, action) => {
                state.userSettingsStatus = APIStatus.failed;
                state.usersListError = action.error;
            })
            .addCase(getUsersActivies.pending, (state, action) => {
                state.userSettingsStatus = APIStatus.loading;
            })
            .addCase(getUsersActivies.fulfilled, (state, action) => {
                state.userSettingsStatus = APIStatus.loaded;
                state.usersActivies = action.payload ? action.payload.data.results : null;
            })
            .addCase(getUsersActivies.rejected, (state, action) => {
                state.userSettingsStatus = APIStatus.failed;
                state.usersActiviesError = action.error;
            })
            .addCase(addUsers.pending, (state, action) => {
                state.userSettingsStatus = APIStatus.loading;
            })
            .addCase(addUsers.fulfilled, (state, action) => {
                state.userSettingsStatus = APIStatus.loaded;
                state.addUserDetail = action.payload;
                state.addUserError = null;
            })
            .addCase(addUsers.rejected, (state, action) => {
                state.userSettingsStatus = APIStatus.failed;
                state.addUserDetail = null;
                state.addUserError = action.error;
            })

            .addCase(updateUser.pending, (state, action) => {
                state.userSettingsStatus = APIStatus.loading;
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.userSettingsStatus = APIStatus.loaded;
                state.updateUserDetail = action.payload;
                state.updateUserError = null;
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.userSettingsStatus = APIStatus.failed;
                state.updateUserDetail = null;
                state.updateUserError = action.error;
            })

            .addCase(deleteUser.pending, (state, action) => {
                state.userSettingsStatus = APIStatus.loading;
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                state.userSettingsStatus = APIStatus.loaded;
                state.deleteUserDetail = action.payload;
                state.deleteUserError = null;
            })
            .addCase(deleteUser.rejected, (state, action) => {
                state.userSettingsStatus = APIStatus.failed;
                state.deleteUserDetail = null;
                state.deleteUserError = action.error;
            });
    }
})

export const { reset, setAppconfigNBIOT, resetAppconfigNBIOT, usersList, addUserDetail, updateUserDetail, deleteUserDetail } = usersSlice.actions

export default usersSlice.reducer