import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { APIStatus } from "./APIStatus";
import axios from "axios";

const initialState = {
  devices: null,
  devicesStatus: APIStatus.loading,
};

export const getRemoteEyeDevices = createAsyncThunk(
  "getRemoteEyeDevices",
  async (payload) => {
    const response = await axios.get(payload.url);
    return response;
  }
);

const rEyeDashboardSlice = createSlice({
  name: "rEyeDashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRemoteEyeDevices.pending, (state, action) => {
        state.devicesStatus = APIStatus.loading;
      })
      .addCase(getRemoteEyeDevices.fulfilled, (state, action) => {
        state.devices = action.payload ? action.payload.data.results : null;
        state.devicesStatus = APIStatus.loaded;
      });
  },
});

export default rEyeDashboardSlice.reducer;
