import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
  root: {
    display: "flex",
    maxWidth: "100vw",
    overflowX: "hidden",
  },
  content: {
    flexGrow: 1,
    padding: 0,
    width: `calc(100vw - 240px)`,
    minHeight: "100vh",
  },
  contentShift: {
    width: `calc(100vw - ${240}px)`, //`calc(100vw - ${240 + '10px'}px)`,
    // transition: theme.transitions.create(["width", "margin"], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
  },
  fakeToolbar: {
    //...theme.mixins.toolbar,
    toolbar: {
      minHeight: 64, // Adjust the minimum height of the toolbar as needed
      backgroundColor: '#f5f5f5', // Example background color
      color: '#333', // Example text color
      // Add other styling properties as needed
    },
  },
}));
