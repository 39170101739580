import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { APIStatus } from "./APIStatus";

const initialState = {
  deviceFeedsStatus: APIStatus.idle,
  deviceFeeds: null,
  deviceFeedsError: null,
  deviceFeedsOptionsStatus: APIStatus.idle,
  deviceFeedsOptions: null,
  deviceFeedsOptionsError: null,
  deviceStreamsStatus: APIStatus.idle,
  deviceStreams: null,
  deviceStreamsError: null,
  deviceLogsStatus: APIStatus.idle,
  deviceLogs: null,
  deviceLogsError: null,
};

export const getDeviceFeedList = createAsyncThunk(
  "getDeviceFeedList",
  async ({ organization, deviceId }) => {
    const response = await axios.get(
      `/${organization}/feeds?device__device_id=${deviceId}`
    );
    return response.data;
  }
);

export const getDeviceFeedListForOptions = createAsyncThunk(
  "getDeviceFeedListForOptions",
  async ({ organization, deviceId }) => {
    const response = await axios.get(
      `/${organization}/feeds?device__device_id=${deviceId}`
    );
    return response.data;
  }
);

export const getDeviceLogs = createAsyncThunk(
  "getDeviceLogs",
  async ({ organization, offset }) => {
    const response = await axios.get(
      `/${organization}/devices-jobs?limit=15&offset=${offset}`
    );
    return response.data;
  }
);

export const getStreamData = createAsyncThunk("getStreamData", async (url) => {
  const response = await axios.get(url);
  return response.data;
});

const diagnosticsSlice = createSlice({
  name: "feeds",
  initialState,
  reducers: {
    updateDeviceFeeds(state, action) {
      state.deviceFeedsStatus = APIStatus.loaded;
      state.deviceFeeds = action.payload;
      state.deviceFeedsError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDeviceFeedList.pending, (state, action) => {
        state.deviceFeedsStatus = APIStatus.loading;
      })
      .addCase(getDeviceFeedList.fulfilled, (state, action) => {
        const feeds = action.payload.results.filter(
          (deviceFeed) => deviceFeed.is_visible
        );
        state.deviceFeedsStatus = APIStatus.loaded;
        state.deviceFeeds = feeds;
        state.deviceFeedsOptions = feeds;
      })
      .addCase(getDeviceFeedList.rejected, (state, action) => {
        state.deviceFeedsStatus = APIStatus.failed;
        state.deviceFeedsError = action.error;
      })
      .addCase(getDeviceLogs.pending, (state, action) => {
        state.deviceLogsStatus = APIStatus.loading;
      })
      .addCase(getDeviceLogs.fulfilled, (state, action) => {
        state.deviceLogsStatus = APIStatus.loaded;
        state.deviceLogs = action.payload;
      })
      .addCase(getDeviceLogs.rejected, (state, action) => {
        state.deviceLogsStatus = APIStatus.failed;
        state.deviceLogsError = action.error;
      })
      .addCase(getDeviceFeedListForOptions.pending, (state, action) => {
        state.deviceFeedsOptionsStatus = APIStatus.loading;
      })
      .addCase(getDeviceFeedListForOptions.fulfilled, (state, action) => {
        state.deviceFeedsOptionsStatus = APIStatus.loaded;
        state.deviceFeedsOptions = action.payload.results.filter(
          (deviceFeed) => deviceFeed.is_visible
        );
      })
      .addCase(getDeviceFeedListForOptions.rejected, (state, action) => {
        state.deviceFeedsOptionsStatus = APIStatus.failed;
        state.deviceFeedsOptionsError = action.error;
      })
      .addCase(getStreamData.pending, (state, action) => {
        state.deviceStreamsStatus = APIStatus.loading;
      })
      .addCase(getStreamData.fulfilled, (state, action) => {
        state.deviceStreamsStatus = APIStatus.loaded;
        state.deviceStreams = action.payload;
      })
      .addCase(getStreamData.rejected, (state, action) => {
        state.deviceStreamsStatus = APIStatus.failed;
        state.deviceStreamsError = action.error;
      });
  },
});

export const { load, updateDeviceFeeds } = diagnosticsSlice.actions;

export default diagnosticsSlice.reducer;
