import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useStyles from "./styles";

const ConfirmDialogContext = React.createContext();

const useConfirmDialog = () => {
  const context = React.useContext(ConfirmDialogContext);
  return context;
};

const rootReducer = (state, action) => {
  switch (action.type) {
    case "SHOW_DIALOG":
      return {
        content: action.payload.content,
        title: action.payload.title,
        open: true,
        onOk: action.payload.onOk,
        // onCancel: action.payload.onCancel
      };
    case "CLOSE_DIALOG":
      return {
        ...state,
        // content: '',
        // title: '',
        open: false,
        // onOk: null,
        // onCancel: null
      };
    case "CLEAR_DATA":
      return {
        content: "",
        title: "",
        open: false,
        onOk: null,
        // onCancel: null
      };
    default:
      return state;
  }
};

const ConfirmDialogProvider = ({ children }) => {
  let classes = useStyles();
  const [state, dispatch] = React.useReducer(rootReducer, {
    content: "",
    title: "",
    open: false,
    onOk: null,
    // onCancel: null
  });

  const closeDialog = () => {
    dispatch({ type: "CLOSE_DIALOG" });
    const timer = setTimeout(() => {
      dispatch({ type: "CLEAR_DATA" });
      clearTimeout(timer);
    }, 100);
  };
  const showDialog = (dialogData) => {
    dispatch({ type: "SHOW_DIALOG", payload: dialogData });
  };

  const onOk = () => {
    closeDialog();
    state.onOk();
  };
  return (
    <ConfirmDialogContext.Provider value={{ showDialog, closeDialog }}>
      {children}

      <Dialog
        open={state.open}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{state.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {state.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={onOk}
            className={classes.deleteButton}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            onClick={closeDialog}
            className={classes.cancelButton}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </ConfirmDialogContext.Provider>
  );
};

export { ConfirmDialogProvider, ConfirmDialogContext, useConfirmDialog };
