import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
  container: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
  },
  logotypeContainer: {
    //backgroundColor: theme.palette.primary.main,
    width: "60%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // [theme.breakpoints.down("md")]: {
    //   width: "50%",
    //   //height:"50%"
    // },
    // [theme.breakpoints.down("sm")]: {
    //   //display: "none",
    //   width: "50%",
    //   //height:"50%"
    // },
    // [theme.breakpoints.down("xs")]: {
    //   //display: "none",
    //   width: "50%",
    //   height:"50%"
    // },
  },
  logotypeImage: {
    width: 400,
    height:200,
    marginBottom: '8px',
    // [theme.breakpoints.down("md")]: {
    //   width: "60%",
    //   height:"30%"
    // },
    // [theme.breakpoints.down("sm")]: {
    //   width: "70%",
    //   height:"15%"
    // },
    // [theme.breakpoints.down("xs")]: {
    //   width: "50%",
    //   height:"30%"
    // },
  },
  smalllogotypeImage: {
    width: 70,
    height:80,
    marginBottom: '4px',
  },
  logotypeText: {
    color: "white",
    fontWeight: 500,
    fontSize: 84,
    // [theme.breakpoints.down("md")]: {
    //   fontSize: 48,
    // },
  },
  formContainer: {
    width: "40%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "'Karla', sans-serif",
    // [theme.breakpoints.down("md")]: {
    //   width: "50%",
    // },
    // [theme.breakpoints.down("xs")]: {
    //   height:"60%"
    // },
  },
  form: {
    width: 320,
  },
  tab: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  greeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: '8px',
  },
  subGreeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: '4px',
  },
  googleButton: {
    marginTop: '10px',
    backgroundColor: "white",
    width: "100%",
    textTransform: "none",
  },
  googleButtonCreating: {
    marginTop: 0,
  },
  googleIcon: {
    width: 30,
    marginRight: '4px',
  },
  creatingButtonContainer: {
    marginTop: '5px',
    height: 46,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  createAccountButton: {
    height: 46,
    textTransform: "none",
  },
  formDividerContainer: {
    marginTop: '8px',
    marginBottom: '8px',
    display: "flex",
    alignItems: "center",
  },
  formDividerWord: {
    paddingLeft: '4px',
    paddingRight: '4px',
  },
  formDivider: {
    flexGrow: 1,
    height: 1,
    //backgroundColor: theme.palette.text.hint + "40",
  },
  errorMessage: {
    textAlign: "center",
  },
  textFieldUnderline: {
    "&:before": {
      //borderBottomColor: theme.palette.primary.light,
    },
    "&:after": {
      //borderBottomColor: theme.palette.primary.main,
    },
    "&:hover:before": {
      //borderBottomColor: `${theme.palette.primary.light} !important`,
    },
  },
  textField: {
    //borderBottomColor: theme.palette.background.light,
  },
  formButtons: {
    width: "100%",
    marginTop: '8px',
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  forgetButton: {
    textTransform: "none",
    fontWeight: 'bold',
    fontSize:'14px'
  },
  loginLoader: {
    marginLeft: '8px',
  },
  copyright: {
    marginTop: '8px',
    whiteSpace: "nowrap",
    fontWeight : "bold",
    // [theme.breakpoints.up("md")]: {
    //   position: "absolute",
    //   bottom: '4px',
    // },
  },
}));
