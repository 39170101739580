import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
  deleteButton: {
    borderRadius: "18px",
  },
  cancelButton: {
    borderRadius: "18px",
  }
}));
