import { makeStyles } from "@mui/styles";

const drawerWidth = 240;

export default makeStyles(theme => ({
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    overflowY: 'overlay',
    width: drawerWidth,
    // transition: theme.transitions.create("width", {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
  },
  drawerClose: {
    // transition: theme.transitions.create("width", {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
    overflowX: "hidden",
    width: '72px',
    // [theme.breakpoints.down("sm")]: {
    //   width: drawerWidth,
    // },
  },
  toolbar: {
    //...theme.mixins.toolbar,
    toolbar: {
      minHeight: 64, // Adjust the minimum height of the toolbar as needed
      backgroundColor: '#f5f5f5', // Example background color
      color: '#333', // Example text color
      // Add other styling properties as needed
    },
    // [theme.breakpoints.down("sm")]: {
    //   display: "none",
    // },
  },
  content: {
    flexGrow: 1,
    padding: '4px',
  },
   sidebarList: {
    marginTop: '10px',
    paddingLeft : '2px',
    paddingRight : '4px',
  }, 
  mobileBackButton: {
    marginTop: '4px',
    marginLeft: '2px',
    // [theme.breakpoints.only("sm")]: {
    //   marginTop: '2px',
    // },
  //   [theme.breakpoints.up("md")]: {
  //     display: "none",
  //   },
   },
}));
