import moment from "moment";
import { isInteger } from "lodash";

export function getCurrentMoment() {
  const momentDate = moment();
  return momentDate.format("MMM DD, YYYY h:mm A");
}


export function datesDifferenceInDays(startDate,endDate) {
  const differenceMs = Math.abs(startDate - endDate);
  // Convert milliseconds to days
  const differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
  return differenceDays;
}

export function capitalizeWords(str) {
  const capitalizeWords = str
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
  return capitalizeWords;
}

export function isWhitespaceString(str) {
  return /^\s*$/.test(str);
}

export function trimAndAddFrontSpace(str, includeFrontSpace = true) {
  const startsWithMoreThanOneSpace = /^\s{2,}/.test(str);
  const trimmedStr = str.trim();
  if (!includeFrontSpace) return trimmedStr;
  if (!startsWithMoreThanOneSpace) return trimmedStr;
  return "  " + trimmedStr;
}

export function trimEndSpace(str) {
  return str.replace(/^\s+/, "");
}

export const isEmptyOrSpaces = (str) => {
  const temStr = str.replace(/\s/g, "");
  return temStr === "" || /^\s*$/.test(temStr);
};

export function validateObjectValues(obj) {
  for (let key in obj) {
    if (typeof obj[key] === "string" && obj[key].trim() === "") {
      return false; // string values must not be empty
    }
    if (
      typeof obj[key] === "number" &&
      (Number.isNaN(parseInt(obj[key])) || obj[key].toString().length === 0)
    ) {
      return false; // number values must have length greater than zero
    }
    if (typeof obj[key] === "object" && !Object.keys(obj[key]).length) {
      return false;
    }
    if (
      typeof obj[key] === "object" &&
      !!Array.isArray(obj[key]) &&
      !obj[key].length
    ) {
      return false;
    }
  }
  return true;
}

export function validateDeviceFeedsValue(obj) {
  for (let key in obj) {
    if (
      typeof obj[key] === "object" &&
      !!Array.isArray(obj[key]) &&
      obj[key].length > 0
    ) {
      return true;
    }
  }
  return false;
}

export function validateMinMaxValues(minVal, maxVal) {
  if (minVal === undefined || minVal === null || minVal === "") {
    return true;
  }
  if (maxVal === undefined || maxVal === null || maxVal === "") {
    return true;
  }
  if (!!minVal.toString() && !!maxVal.toString()) {
    return Number(minVal) < Number(maxVal); // maxVal should be greater than maxVal
  }
  return true;
}

export function formatStringWithDates(textValue, dates) {
  const parsedDates = dates.map((date) => new Date(date));
  const formattedDates = parsedDates.map((date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
      date
    );
    const year = date.getFullYear().toString();
    return `${day}${month}${year}`;
  });
  return `${textValue}-${formattedDates[0]}-${formattedDates[1]}`;
}

export function removeStringSpaces(value) {
  return value.replace(/\s/g, "");
}

export function validateTimeRange(timeRange) {
  const startTime = new Date(timeRange.startTime);
  const endTime = new Date(timeRange.endTime);
  return startTime <= endTime;
}

export function getErrorFormattedMessage(errorObject) {
  let errorMessage = "An Error Occurred Please Try Again";
  if (
    !errorObject ||
    typeof errorObject === "string" ||
    Object.keys(errorObject).length === 0
  )
    return errorMessage;
  for (const errorKey of Object.keys(errorObject)) {
    const errorValue = errorObject[errorKey];
    if (typeof errorValue === "string") {
      const beautifiedProp =
        errorKey.charAt(0).toUpperCase() + errorKey.slice(1);
      errorMessage = `${beautifiedProp} - ${errorValue}`;
      break;
    }
    if (typeof errorValue === "object") {
      if (errorValue.length === 0) continue;
      const errorStr = errorValue[0];
      const beautifiedProp = errorKey
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      errorMessage = `${beautifiedProp} - ${errorStr}`;
      break;
    }
  }
  return errorMessage;
}

export class CustomException extends Error {
  constructor(message) {
    super(message);
    this.name = "CustomException";
  }
}

export function dateFormat(date) {
  return moment(date).format("DD-MMM-YYYY");
}

export function formatDateAndMonth(date) {
  return moment(date).format("DD MMM, hh:mm a");
}

export function getWidgetRainfallTimeRange(widget) {
  let startTime = "";
  let endTime = "";
  const widgetTimeFormat = "YYYY-MM-DD HH:mm:ss";
  if (widget.data.scaleValue) {
    if (widget.data.scaleValue === "latest") {
      const time = moment.duration("24:00:00");
      startTime = moment().subtract(time).format(widgetTimeFormat).valueOf();
      endTime = moment().format(widgetTimeFormat).valueOf();
    } else if (widget.data.scaleValue === "timeperiod") {
      startTime = moment(widget.data.startTime).format(widgetTimeFormat);
      endTime = moment(widget.data.endTime).format(widgetTimeFormat);
    } else if (widget.data.scaleValue === "timeperiodSingle") {
      startTime = moment.utc(widget.data.startTime).format(widgetTimeFormat);
      endTime = moment.utc(widget.data.endTime).format(widgetTimeFormat);
    } else if (
      widget.data.scaleValue === "timeperiodWeek" ||
      widget.data.scaleValue === "timeperiodMonth"
    ) {
      startTime = moment(widget.data.startTime).format(widgetTimeFormat);
      endTime = moment(widget.data.endTime).format(widgetTimeFormat);
    } else {
      const time = moment.duration(widget.data.scaleValue);
      startTime = moment().subtract(time).format(widgetTimeFormat).valueOf();
      endTime = moment().format(widgetTimeFormat).valueOf();
    }
  }
  return { startTime, endTime };
}

export const getParsedNumber = (value) => {
  if (value === null || value === undefined || value === "") {
    return null;
  }
  if (isInteger(value) === true) {
    return parseInt(value);
  }
  return +parseFloat(value).toFixed(value.length);
};

export function convertToSnakeCase(inputString) {
  const lowerCaseString = inputString.toLowerCase();
  const words = lowerCaseString.split(" ");
  return words.join("_");
}

export function validateOrgCustomInput(input) {
  if (input.type === "boolean" && typeof input.value !== "boolean") {
    return false;
  }
  if (
    (input.type === "array_of_string" || input.type === "array_of_option") &&
    !Array.isArray(input.value)
  ) {
    return false;
  }
  if (
    input.type === "array_of_option" &&
    !!Array.isArray(input.value) &&
    input.value.length > 0
  ) {
    for (const inputVal of input.value) {
      if (!inputVal) {
        return false;
      } else if (
        inputVal &&
        typeof inputVal.text === "string" &&
        !inputVal.text.trim()
      ) {
        return false;
      } else if (
        inputVal &&
        typeof inputVal.value === "string" &&
        !inputVal.value.trim()
      ) {
        return false;
      } else if (
        inputVal &&
        typeof inputVal.value === "number" &&
        !inputVal.value.toString().trim()
      ) {
        return false;
      }
    }
  }
  return true;
}

export function getRainfallSensorDevice(dashboardConfig) {
  let rainfallDataSource;
  if (
    dashboardConfig &&
    typeof dashboardConfig.rainfall_data_source === "object"
  ) {
    rainfallDataSource = dashboardConfig.rainfall_data_source.value;
  } else if (dashboardConfig && !!dashboardConfig.rainfall_data_source) {
    rainfallDataSource = dashboardConfig.rainfall_data_source;
  } else {
    rainfallDataSource = null;
  }
  const rainfallSensorDevice =
    !!rainfallDataSource && rainfallDataSource !== "public_api"
      ? rainfallDataSource
      : null;
  return rainfallSensorDevice;
}

export function getCatchmentOption(dashboardConfig) {
  const defaultCatchmentOption = [
    { name: "All Catchments", value: "all" },
    { name: "Area - 1", value: 1 },
    { name: "Area - 2", value: 2 },
    { name: "Area - 3", value: 3 },
    { name: "Area - 4", value: 4 },
    { name: "Area - 5", value: 5 },
    { name: "Area - 6", value: 6 },
  ];
  let catchmentNameValues;
  if (dashboardConfig && typeof dashboardConfig.catchment_name === "object") {
    catchmentNameValues = dashboardConfig.catchment_name.value;
  } else if (dashboardConfig && !!dashboardConfig.catchment_name) {
    catchmentNameValues = dashboardConfig.catchment_name;
  } else {
    catchmentNameValues = null;
  }
  if (!catchmentNameValues) {
    return defaultCatchmentOption;
  }
  const finalCatchmentOption = catchmentNameValues.map((catchmentValue) => {
    return { name: catchmentValue.text, value: catchmentValue.value };
  });
  //Adding All column option initially..
  finalCatchmentOption.unshift({ name: "All Catchments", value: "all" });
  return finalCatchmentOption;
}

export function getShowPipeAssets(dashboardConfig) {
  let showPipeAssets = false;
  if (dashboardConfig && typeof dashboardConfig.show_pipe_assets === "object") {
    showPipeAssets = dashboardConfig.show_pipe_assets.value;
  } else if (dashboardConfig && !!dashboardConfig.show_pipe_assets) {
    showPipeAssets = dashboardConfig.show_pipe_assets;
  }
  return showPipeAssets;
}

export function getDeviceColumnsList(dashboardConfig) {
  let deviceColumnsList = [];
  if (
    dashboardConfig &&
    typeof dashboardConfig.device_columns_list === "object"
  ) {
    deviceColumnsList = dashboardConfig.device_columns_list.value;
  } else if (dashboardConfig && !!dashboardConfig.device_columns_list) {
    deviceColumnsList = dashboardConfig.device_columns_list;
  }
  return deviceColumnsList;
}

export function getBlockageDeviceIdsList(dashboardConfig) {
  let blockageDeviceIdsList = [];
  if (
    dashboardConfig &&
    typeof dashboardConfig.blockage_device_ids === "object"
  ) {
    blockageDeviceIdsList = dashboardConfig.blockage_device_ids.value;
  } else if (dashboardConfig && !!dashboardConfig.blockage_device_ids) {
    blockageDeviceIdsList = dashboardConfig.blockage_device_ids;
  }
  return blockageDeviceIdsList;
}

export function formatMeterDetailFileName(deviceName, dates) {
  const parsedDates = dates.map((date) => new Date(date));
  const formattedDates = parsedDates.map((date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
      date
    );
    const year = date.getFullYear().toString();
    return `${day}-${month}-${year}`;
  });
  return `${deviceName} (${formattedDates[0]}-${formattedDates[1]})`;
}

export function getDeviceFeedsFromSelectedNodeKeys(selectedNodeKeys, treeNode) {
  const selectedData = {};
  if (selectedNodeKeys && treeNode) {
    const selectedKeys = Object.keys(selectedNodeKeys);
    for (const key in treeNode) {
      const parentNode = treeNode[key];
      if (
        selectedNodeKeys[parentNode.key] &&
        !!selectedNodeKeys[parentNode.key]?.checked
      ) {
        selectedData[parentNode.data] = parentNode.children.map(
          (childNode) => childNode.data
        );
      } else if (
        selectedNodeKeys[parentNode.key] &&
        !selectedNodeKeys[parentNode.key]?.checked
      ) {
        selectedData[parentNode.data] = parentNode.children
          .filter((childNode) => selectedKeys.includes(childNode.key))
          .map((childNode) => childNode.data);
      }
    }
  }
  return selectedData;
}

export function getSelectedNodeKeysFromDeviceFeeds(selectedData, treeNode) {
  const selectedNodeKeys = {};
  if (selectedData && treeNode) {
    for (const key in treeNode) {
      const parentNode = treeNode[key];
      const selectedChildNodes = selectedData[parentNode.data];
      if (selectedChildNodes) {
        const selectedNodes = parentNode.children.filter((node) =>
          selectedChildNodes.includes(node.data)
        );
        selectedNodeKeys[parentNode.key] = {
          checked: selectedNodes.length === parentNode.children.length,
          partialChecked:
            selectedNodes.length > 0 &&
            selectedNodes.length < parentNode.children.length,
        };
        selectedNodes.forEach((node) => {
          selectedNodeKeys[node.key] = {
            checked: true,
            partialChecked: false,
          };
        });
      }
    }
  }
  return selectedNodeKeys;
}

export function getWidgetTimeRange(widget) {
  let startTime = "";
  let endTime = "";
  const widgetTimeFormat = "YYYY-MM-DD HH:mm:ss";
  if (widget.data.scaleValue) {
    if (widget.data.scaleValue === "latest") {
    } else if (widget.data.scaleValue === "timeperiod") {
      // let sgTime = moment.duration("08:00:00");
      startTime = moment(widget.data.startTime).format(widgetTimeFormat);
      endTime = moment(widget.data.endTime).format(widgetTimeFormat);
    } else if (widget.data.scaleValue === "timeperiodSingle") {
      // let sgTime = moment.duration("08:00:00");
      startTime = moment(widget.data.startTime).format(widgetTimeFormat);
      endTime = moment(widget.data.endTime).format(widgetTimeFormat);
    } else {
      let time = moment.duration(widget.data.scaleValue);
      // let sgTime = moment.duration("08:00:00");
      startTime = moment
        .utc()
        .subtract(time)
        .format(widgetTimeFormat)
        .valueOf();
      endTime = moment.utc().format(widgetTimeFormat).valueOf();
    }
  }
  return {
    startTime: startTime,
    endTime: endTime,
  };
}

const formatNumber = (amount, precision) => {
  // Check if the number has more than two decimal places
  if (amount % 1 !== 0 && amount.toString().split(".")[1].length > precision) {
    const parsedAmount = Number(amount);
    return parsedAmount.toFixed(precision); // Round to two decimal places
  } else {
    return amount; // No need to round, return the number as string
  }
};

export const getPrecisionValue = (amount, precision) => {
  if (isInteger(amount)) {
    return amount;
  }
  return formatNumber(amount, precision || 3);
};
