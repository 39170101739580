import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { APIStatus } from './APIStatus'
import { CustomException } from "../util/util";

const initialState = {
    profileSettingsStatus: APIStatus.idle,
    profileSettings: null,
    profileSettingsError: null,
    updateProfileSettingsStatus: APIStatus.idle,
    updateProfileSettingsReceipt: null,
    updateProfileSettingsError: null,
}

export const getProfileSettings = createAsyncThunk('getProfileSettings', async (payload) => {
    const response = await axios.get(`/${payload.organization}/users/${payload.userName}/settings`);
    return response;
})

export const updateProfileSettings = createAsyncThunk('updateProfileSettings', async (payload) => {
    const response = await axios.patch(`/${payload.organization}/users/${payload.userName}/settings`, payload.data)
        .catch((error) => {
            const errorData = JSON.stringify(error.response.data);
            throw new CustomException(errorData);
        });;
    return response;
})

const profileSettingsSlice = createSlice({
    name: 'profileSettingsSlice',
    initialState,
    reducers: {
        reset(state, action) {
            state = { ...initialState };
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getProfileSettings.pending, (state, action) => {
                state.profileSettingsStatus = APIStatus.loading;
            })
            .addCase(getProfileSettings.fulfilled, (state, action) => {
                state.profileSettingsStatus = APIStatus.loaded;
                state.profileSettings = action.payload ? action.payload.data : null;
            })
            .addCase(getProfileSettings.rejected, (state, action) => {
                state.profileSettingsStatus = APIStatus.failed;
                state.profileSettingsError = action.error;
            })
            .addCase(updateProfileSettings.pending, (state, action) => {
                state.updateProfileSettingsStatus = APIStatus.loading;
            })
            .addCase(updateProfileSettings.fulfilled, (state, action) => {
                state.updateProfileSettingsStatus = APIStatus.loaded;
                state.updateProfileSettingsReceipt = action.payload ? action.payload.data : null;
            })
            .addCase(updateProfileSettings.rejected, (state, action) => {
                state.updateProfileSettingsStatus = APIStatus.failed;
                const errorData = JSON.parse(action.error.message);
                state.updateProfileSettingsError = errorData;
            })
    }
})

export const { reset } = profileSettingsSlice.actions

export default profileSettingsSlice.reducer